import { css } from 'styled-components';

const variables = css`
  :root {
    --body: var(--light-grey);
    --nav: rgba(237, 236, 235, 0.85);
    --primary-color: var(--red);
    --title: var(--grey);
    --light-color-theme: var(--lightest-red);
    --scrollbar-base: var(--light-grey);
    --scrollbar-dark:  var(--grey);

    --dark-navy: #020c1b;
    --navy: #0a192f;
    --light-navy: #112240;
    --lightest-navy: #233554;
    --navy-shadow: rgba(2, 12, 27, 0.7);
    --dark-slate: #495670;
    --slate: #1a1a1a;
    --light-slate: #383a42;
    --lightest-slate: #4a4c57;
    --green: #64ffda;
    --green-tint: rgba(100, 255, 218, 0.1);
    --pink: #f57dff;
    --blue: #57cbff;
    --light-grey:#f6f6f6;
    --grey:#292929;
    --dark-grey: #1a1a1a;
    --lightest-red: #e88585;
    --light-red: #f3453a;
    --dark-brown: #1c1916;
    --red: #fd5353;
    --lightest-white:#646464;
    --white: #ffffff;

    --font-sans: 'Calibre', 'Inter', 'San Francisco', 'SF Pro Text', -apple-system, system-ui,
      sans-serif;
    --font-mono: 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', monospace;

    --fz-xxs: 16px;
    --fz-xs: 17px;
    --fz-sm: 18px;
    --fz-md: 20px;
    --fz-lg: 21px;
    --fz-xl: 23px;
    --fz-xxl: 24px;
    --fz-heading: 65px;
    --fz-hero-heading: 100px;

    --border-radius: 5px;
    --btn-border-radius: 50px;
    --nav-height: 100px;
    --nav-scroll-height: 70px;

    --tab-height: 42px;
    --tab-width: 120px;

    --easing: cubic-bezier(0.645, 0.045, 0.355, 1);
    --transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);

    --hamburger-width: 30px;

    --ham-before: top 0.1s ease-in 0.25s, opacity 0.1s ease-in;
    --ham-before-active: top 0.1s ease-out, opacity 0.1s ease-out 0.12s;
    --ham-after: bottom 0.1s ease-in 0.25s, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    --ham-after-active: bottom 0.1s ease-out,
      transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
  }

  [data-theme="dark"] {
    --body: var(--dark-grey);
    --nav: rgba(26, 26, 26, 0.85);
    --slate: #8892b0;
    --light-slate: #a8b2d1;
    --lightest-slate: #ccd6f6;
    --title: var(--lightest-slate);
    --light-color-theme: var(--lightest-white);
    --scrollbar-base: var(--grey);
    --scrollbar-dark: var(--lightest-slate);
  }

  @media(prefers-color-scheme: dark){
    --body: var(--dark-grey);
    --nav: rgba(26, 26, 26, 0.85);
    --slate: #8892b0;
    --light-slate: #a8b2d1;
    --lightest-slate: #ccd6f6;
    --title: var(--lightest-slate);
    --light-color-theme: var(--lightest-white);
    --scrollbar-base: var(--grey);
    --scrollbar-dark: var(--lightest-slate);
  }
`;

export default variables;
