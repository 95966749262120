import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75 75">
    <title>Loader Logo</title>
    <g>
      <g id="F" transform="translate(-4.000000, 2.000000)">
        <path d="M54.4,40.9c1.3,1.4,0.5,1.8,0.4,1.8c-1.3,0.4-9.4,0.7-9.7,0.7c-0.9,0.2-1.2,0.7-1.2,1.9c0,0.2,0.2,9.6,0.2,9.7
	c-0.2,1.1-1.4,1.5-2.3,0.6C38.4,52,35,48.2,31.6,44.5c-0.5-0.6-0.6-1.4-0.7-2.2c0-3.3-0.1-6.6-0.1-9.9c0-1.9,0.7-2.7,2.2-2.6
	c2.1,0.1,4.2,0.1,6.4,0.1c0.4,0,1,0.1,1.2-0.4c0.2-0.6-0.3-1-0.7-1.4c-2.3-2.6-4.7-5.3-7-7.9c-0.6-0.7-1.5-1.2-1.3-2.3
	c0.1-0.7,0.5-0.9,0.9-1c0.4-0.1,20.7-0.1,20.9-0.1c2,0.1,2.3,0.7,2.3,3.5c-0.1,2.7-0.1,5.4-0.1,8.1c0.1,2-0.7,2.6-2.2,2.9
	c-1.7,0.3-3.4,0.2-5.2,0.3c-0.4,0-0.8-0.1-1,0.5C47,32.7,54.2,40.7,54.4,40.9z"
        fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M22.3,24.7c-1.5-1.8-3.9-4.5-3.9-4.5s-2.1-2.1,0.2-4.4C27.1,7.4,40.1,5,51,10c11.3,5.1,17.9,16.5,16.9,28.9
		c-0.1,1.5-0.4,2.2-2.2,2.1c-1.7,0-4.2,0-4.2,0s0.7,1,1,1.3c4.2,5,4.2,5,0.5,10.5c-8.1,12-23.9,16.1-36.9,9.7
		C13.1,56.1,6.9,41.1,11.4,27.3c1-3,4.2-2.7,4.2-2.7S19.8,24.7,22.3,24.7z"/>
    </g>
  </svg>
);

export default IconLoader;
