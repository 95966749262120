import React from 'react';
import { IconSun, IconMoon } from '@components/icons';
import styled from 'styled-components';

const StyledMode = styled.div`
  .dark_mode {
    margin-left: 20px;
  }

  .dark_mode_label {
      width: 65px;
      height: 30px;
      position: relative;
      display: block;
      background: #ebebeb;
      border-radius: 200px;
      cursor: pointer;
      transition: var(--transition);
  }
  .dark_mode_label:after {
      content: "";
      width: 25px;
      height: 25px;
      position: absolute;
      top: 3px;
      left: 3px;
      background: var(--primary-color);
      border-radius: 180px;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
      transition: var(--transition);
  }
  .dark_mode_input {
      width: 0;
      height: 0;
      visibility: hidden;
  }
  .dark_mode_input:checked + .dark_mode_label {
      background: #242424;
  }
  .dark_mode_input:checked + .dark_mode_label:after {
      left: 62px;
      transform: translateX(-100%);
      background: #3a3a3a;
  }
  .dark_mode_label:active:after {
      width: 30px;
  }

  .dark_mode_label svg {
      position: absolute;
      width: 18px;
      z-index: 100;
  }
  .dark_mode_label svg.sun {
      left: 7px;
      fill: #fff;
      transition: var(--transition);
  }
  .dark_mode_label svg.moon {
      left: 42px;
      fill: #7e7e7e;
      transition: var(--transition);
  }
  .dark_mode_input:checked + .dark_mode_label svg.sun {
      fill: #7e7e7e;
  }
  .dark_mode_input:checked + .dark_mode_label svg.moon {
      fill: #fff;
  }

`;

const DarkMode = () => {
  const setThemeColor = color => {
    document.querySelector('body').setAttribute('data-theme', color);
    if (typeof window !== 'undefined') {
      localStorage.setItem('theme', color);
    }
  };

  const toggleTheme = e => {
    if (e.target.checked) {
      setThemeColor('dark');
    } else {
      setThemeColor('light');
    }
  };

  let selectedTheme = null;
  //we are running on the client
  if (typeof window !== 'undefined') {
    selectedTheme = localStorage.getItem('theme');
  }

  if (selectedTheme === 'dark') {
    setThemeColor('dark');
  }

  return (
    <StyledMode>
      <div className='dark_mode'>
        <input
          className='dark_mode_input hidden'
          type='checkbox'
          id='theme-toggle'
          onChange={toggleTheme}
          defaultChecked={selectedTheme === 'dark'}
        />
        <label className='dark_mode_label' htmlFor='theme-toggle'>
          <IconSun />
          <IconMoon />
        </label>
      </div>
    </StyledMode>
  );
};

export default DarkMode;
