import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 75 75">
    <title>Logo</title>
    <g>
      <path fill="#1A1A1A" d="M22.3,24.7c-1.5-1.8-3.9-4.5-3.9-4.5s-2.1-2.1,0.2-4.4C27.1,7.4,40.1,5,51,10c11.3,5.1,17.9,16.5,16.9,28.9
		c-0.1,1.5-0.4,2.2-2.2,2.1c-1.7,0-4.2,0-4.2,0s0.7,1,1,1.3c4.2,5,4.2,5,0.5,10.5c-8.1,12-23.9,16.1-36.9,9.7
		C13.1,56.1,6.9,41.1,11.4,27.3c1-3,4.2-2.7,4.2-2.7S19.8,24.7,22.3,24.7z"/>
      <path fill="#FEFEFE" d="M40.9,35c3.9,3.9,7.9,7.9,12.4,12.4c-4,0-7.4,0.1-10.8,0c-2.1-0.1-2.9,0.4-2.8,2.7c0.2,3.1,0,6.3-0.1,9.4
		c-6.1-2.5-11.5-10.3-11.5-16.6c0-8.4,0-8.5,8.5-8.5C38.1,34.3,39.7,33.4,40.9,35z"/>
      <path  fill="#FEFDFD" d="M53.4,22c-0.1,3-0.4,6.1-0.1,9c0.3,2.7-0.7,3.1-3.1,3c-2.8-0.2-5.6,0-8.3,0c-0.6,0.1-1.2,0-1.6-0.4
		c-3.4-3.2-6.1-7.2-9.6-10.2c-2.3-2-2.1-3.3,1.3-2.9c6.4,0.8,12.9-0.1,19.3,0C52.1,20.6,53,20.8,53.4,22z"/>
      <path  fill="#FD5353" d="M53.4,22c-0.5-0.6-1-0.9-1.8-0.9c-7.1,0-14.2,0-22.5,0c4.7,4.8,8.7,8.9,12.7,13c-0.3,0.3-0.6,0.6-0.9,1
		c-0.4,0-0.7,0-1.1,0c-1.2,0-3.4-0.2-5.6-0.1C29,35,29,35.5,29,37.2c0.1,3.1,0.1,10.4,0.2,10.8c0.2,0.7,10.5,11.5,10.5,11.5l0,2.3
		c0,0-0.1-0.1-0.1-0.1c-4.6-4.5-13-13.4-13-13.4s-0.5-9.4-0.4-12.9c0-1.3-0.1-1.3,2-1.4c3.5,0,6.9,0,11.5,0
		c-4.9-4.9-9.2-9.1-14.2-14c4.8,0,8.7,0,12.6,0c4.3,0,8.6,0,13,0C52.5,20,53.9,19.9,53.4,22z"/>
    </g>
  </svg>
);

export default IconLogo;
