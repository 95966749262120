import React from 'react';

const IconCirlce = () => (
  <svg id="hex" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 75 75">
    <title>Circle</title>
    <g>
      <path fill="currentColor" d="M22.3,24.7c-1.5-1.8-3.9-4.5-3.9-4.5s-2.1-2.1,0.2-4.4C27.1,7.4,40.1,5,51,10c11.3,5.1,17.9,16.5,16.9,28.9
        c-0.1,1.5-0.4,2.2-2.2,2.1c-1.7,0-4.2,0-4.2,0s0.7,1,1,1.3c4.2,5,4.2,5,0.5,10.5c-8.1,12-23.9,16.1-36.9,9.7
        C13.1,56.1,6.9,41.1,11.4,27.3c1-3,4.2-2.7,4.2-2.7S19.8,24.7,22.3,24.7z"/>
    </g>
  </svg>
);

export default IconCirlce;
